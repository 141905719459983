import {
  DownOutlined,
  EnvironmentOutlined,
  MailOutlined,
  ShoppingCartOutlined,
  StarFilled,
} from '@ant-design/icons';
import {
  AmbassadorMini,
  FishingReport,
  Product,
  TechniqueFull,
  Waterbody,
} from '@omniafishing/core';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import pluralize from 'pluralize';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { isNotStarted, LoadingState } from '../../constants/loading_state';
import { useCart } from '../../hooks/use_cart';
import { useQueryString } from '../../hooks/use_query_string';
import { useUserAmbassadors } from '../../hooks/use_user_ambassadors';
import { useUserWaterbodies } from '../../hooks/use_user_waterbodies';
import { apiV1 } from '../../lib/api';
import { customAttributesToAttributionQueryParams } from '../../lib/line_item_attribution';
import { isProductInStock } from '../../lib/products';
import { GlobalQueryParams, OmniaQueryParams } from '../../lib/query_string';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { toDollars } from '../../lib/to_dollars';
import { OmniaUrls } from '../../lib/urls';
import { NAME_FALLBACK } from '../../lib/user';
import {
  ClickKey_ProductCard,
  WebAnalytics,
  WebAnalyticsEventAreas,
} from '../../lib/web_analytics';
import { LineItemCustomAttribute } from '../../redux/cart';
import { InventoryModalActions } from '../../redux/inventory_modal';
import { getBrands } from '../../redux/reference_data';
import { ProductDetailPageHashes } from '../../routes/product_detail_page/product_detail_page';
import { AmbassadorLink } from '../ambassador_link/ambassador_link';
import { FishingReportsStatsPopover } from '../fishing_reports_stats/fishing_reports_stats_popover';
import { OmniaButton } from '../omnia_button/omnia_button';
import SvgFishingReport from '../svg/fishing_report';
import SvgTechniques from '../svg/new_product_transparent';
import SvgTackleBox from '../svg/tackle_box';
import { UserProfileImgRinged } from '../user_profile_img_ringed/user_profile_img_ringed';
import { TrendingProduct } from '../waterbody_detail/waterbody_detail';
import { LinkToProductDetailPage } from './link_to_pdp';
import styles from './product_card.less';
import { ProductChip } from './product_chip';

dayjs.extend(relativeTime);

export interface ProductCardProps {
  addToCartArea?: WebAnalyticsEventAreas;
  ambassadorFishingReports?: FishingReport[];
  ambassadorWithAvatar?: AmbassadorMini;
  area?: WebAnalyticsEventAreas;
  buildKit?: boolean;
  className?: string;
  fishingReportTechnique?: TechniqueFull;
  hideViewAllLink?: boolean;
  icon?: React.ReactNode;
  lineItemAttribution?: LineItemCustomAttribute[];
  list_uuid: string;
  onClick?: () => void;
  onKitClick?: (report: FishingReport) => void;
  onReportedOnLakeClick?: () => void;
  onTechniqueClick?: () => void;
  position: number;
  product: Product | TrendingProduct;
  reportedByAmbassador?: AmbassadorMini;
  reportedOnLake?: Waterbody;
  singleSourceFishingReport?: FishingReport;
  skeleton?: boolean;
  stateBestSeller?: string;
}

export const ProductCard = (props: ProductCardProps) => {
  const {
    addToCartArea,
    ambassadorFishingReports,
    ambassadorWithAvatar,
    area = WebAnalyticsEventAreas.MAIN_VIEW,
    buildKit,
    className,
    fishingReportTechnique,
    hideViewAllLink,
    icon,
    lineItemAttribution = [],
    list_uuid,
    onClick,
    onKitClick,
    onReportedOnLakeClick,
    onTechniqueClick,
    position,
    product,
    reportedByAmbassador,
    reportedOnLake,
    singleSourceFishingReport,
    skeleton,
    stateBestSeller,
  } = props;

  const {
    compare_at_price,
    img_url,
    on_sale,
    price,
    sku,
    title_short,
    zone_1_fishing_reports_count,
    zone_2_fishing_reports_count,
    zone_3_fishing_reports_count,
    popularity,
    created_at,
    shopify_product_id,
    in_stock,
  } = product;
  const history = useHistory();
  const dispatch = useDispatch();
  const allBrands = useSelector(getBrands);
  const brand = allBrands.find((b) => b.name === product.brand);
  const [fishingReports, setFishingReports] = useState<FishingReport[]>(
    ambassadorFishingReports?.length ? ambassadorFishingReports : []
  );
  const { replaceQueryString } = useQueryString();
  const [fishingReportLoadingState, setFishingReportsLoadingState] = useState(
    LoadingState.NOT_STARTED
  );
  const shouldFetchFishingReports =
    isNotStarted(fishingReportLoadingState) && !ambassadorFishingReports?.length;
  const { addToCart } = useCart();

  const { userWaterbodies } = useUserWaterbodies();
  const userWaterbodySlugs = userWaterbodies.map((w) => w.url_slug);
  const { userAmbassadors } = useUserAmbassadors();
  const userAmbassadorSlugs = userAmbassadors.map((a) => a.slug);

  const productCountsFromZones =
    zone_1_fishing_reports_count + zone_2_fishing_reports_count + zone_3_fishing_reports_count;
  const fishingReportsCount = ambassadorFishingReports?.length
    ? ambassadorFishingReports?.length
    : productCountsFromZones;
  const outOfStock = !isProductInStock(product);
  const reportedByName = reportedByAmbassador?.full_name || NAME_FALLBACK;

  const lineItemCustomAttributes = [...lineItemAttribution];

  const report_date =
    singleSourceFishingReport?.outing_date || singleSourceFishingReport?.accepted_at;
  const reportDate = dayjs(report_date);
  const now = dayjs();

  const reportOver7DaysOld = now.diff(reportDate, 'day') >= 7;
  const formattedReportDate = reportOver7DaysOld ? reportDate.format('MMM DD') : now.to(reportDate); // this syntax uses dayjs plugin relativeTime

  const trending = 'trending' in product && product.trending;

  const webAnalyticsClick = (click_key: ClickKey_ProductCard) => {
    WebAnalytics.productCardClick({
      card_type: 'Product',
      product_family_id: Number(shopify_product_id),
      product_sku: sku,
      click_key,
    });
  };

  const onOutOfStockClick = useCallback(() => {
    dispatch(InventoryModalActions.INVENTORY_MODAL_OPEN(product, area));
    webAnalyticsClick('[product_card].(cta)');
  }, [product, area, dispatch]);

  const onCartAdd = useCallback(() => {
    addToCart({
      productsToAdd: [
        {
          product,
          quantity: 1,
        },
      ],
      addToCartArea,
      customAttributes: lineItemCustomAttributes,
      position,
      list_uuid,
    });
    webAnalyticsClick('[product_card].(cta)');
  }, [addToCart, product, lineItemCustomAttributes, addToCartArea]);

  const commonLinkProps = {
    lineItemAttribution,
    product,
    position,
    onClick,
    skeleton,
    list_uuid,
  };

  return (
    <div
      className={classNames(styles.product, className, {
        [styles.skeleton]: skeleton,
      })}
    >
      <LinkToProductDetailPage {...commonLinkProps} className={styles.imgWrapper}>
        <div
          className={styles.productImg}
          onClick={() => {
            webAnalyticsClick('[product_card].(image)');
          }}
        >
          <img
            src={setShopifyImgWidth(img_url, 300)}
            alt={title_short}
            loading="lazy"
            width="250"
            height="250"
          />
        </div>
        {icon && <span className={styles.icon}>{icon}</span>}
        {!skeleton && singleSourceFishingReport && (
          <span className={styles.badge__reportedDate}>Reported {formattedReportDate}</span>
        )}
        {!skeleton && (
          <ProductChip
            stateBestSeller={!!stateBestSeller}
            createdAt={created_at}
            onSale={on_sale}
            popularity={popularity}
            trending={trending}
          />
        )}
      </LinkToProductDetailPage>
      {ambassadorWithAvatar && (
        <AmbassadorLink
          ambassador={singleSourceFishingReport.user}
          target="_blank"
          className={styles.amboProfile}
        >
          <UserProfileImgRinged
            image={singleSourceFishingReport.user.image}
            imageSize={28}
            role={singleSourceFishingReport.user.role}
            borderSize={2}
          />
          <span>{singleSourceFishingReport.user.full_name}</span>
        </AmbassadorLink>
      )}
      {brand && (
        <p className={styles.brand}>
          {skeleton ? (
            brand.display_name
          ) : (
            <Link
              to={OmniaUrls.brand(brand)}
              style={{ color: 'currentcolor' }}
              onClick={() => {
                webAnalyticsClick('[product_card].(brand)');
              }}
            >
              {brand.display_name}
            </Link>
          )}
        </p>
      )}
      <LinkToProductDetailPage
        {...commonLinkProps}
        onClick={() => {
          webAnalyticsClick('[product_card].(title)');
        }}
      >
        <p className={styles.title}>{title_short}</p>
      </LinkToProductDetailPage>

      {singleSourceFishingReport || fishingReportsCount === 1 ? (
        <>
          <p
            className={styles.viewFishingReport}
            onClick={() =>
              replaceQueryString({
                [GlobalQueryParams.report_id]:
                  singleSourceFishingReport?.id || fishingReports[0].id,
              })
            }
          >
            <SvgFishingReport className={styles.inlineIcon} width={16} height={16} />
            <span>View Fishing Report</span>
          </p>
        </>
      ) : (
        fishingReportsCount > 0 && (
          <FishingReportsStatsPopover
            fishingReports={fishingReports}
            products={[product]}
            onViewAllReportsClick={() => {
              history.push({
                pathname: OmniaUrls.product(
                  product.shopify_product_handle,
                  product.shopify_options
                ),
                search: `?${customAttributesToAttributionQueryParams(lineItemAttribution)}`,
                hash: ProductDetailPageHashes.fishing_reports,
              });
            }}
            loadingState={fishingReportLoadingState}
            placement="bottom"
            className={styles.fishingReportsPopoverWrapper}
            hideArrow
            hideViewAllLink={hideViewAllLink}
          >
            <p
              className={styles.fishingReportsCount}
              onClick={() => {
                webAnalyticsClick('[product_card].(stats_widget)');
                if (shouldFetchFishingReports) {
                  setFishingReportsLoadingState(LoadingState.PENDING);
                  apiV1.productFishingReportsFetch(sku).then((res) => {
                    setFishingReports(res.data.data);
                    setFishingReportsLoadingState(LoadingState.DONE);
                  });
                }
              }}
            >
              <SvgFishingReport className={styles.inlineIcon} width={16} height={16} />
              <span>
                {fishingReportsCount} <em>{pluralize('Fishing Reports', fishingReportsCount)}</em>
                <DownOutlined />
              </span>
            </p>
          </FishingReportsStatsPopover>
        )
      )}

      {reportedOnLake && (
        <Link
          to={OmniaUrls.waterbody(reportedOnLake)}
          onClick={() => {
            webAnalyticsClick('[product_card].(reported_on)');
            onReportedOnLakeClick?.();
          }}
          className={styles.reportedOnLake}
        >
          <EnvironmentOutlined className={styles.inlineIcon} />
          <span>
            Reported on{' '}
            <span className={styles.noBreak}>
              <em>{reportedOnLake.primary_name}</em>
              {userWaterbodySlugs.includes(reportedOnLake.url_slug) && (
                <StarFilled className={styles.star} />
              )}
            </span>
          </span>
        </Link>
      )}

      {reportedByAmbassador && (
        <AmbassadorLink
          ambassador={reportedByAmbassador}
          onClick={() => {
            webAnalyticsClick('[product_card].(reported_on)');
            onReportedOnLakeClick?.();
          }}
          className={styles.reportedByAmbassador}
        >
          <EnvironmentOutlined className={styles.inlineIcon} />
          <span>
            Reported by{' '}
            <span className={styles.noBreak}>
              <em>{reportedByName}</em>
              {userAmbassadorSlugs.includes(reportedByAmbassador.slug) && (
                <StarFilled className={styles.star} />
              )}
            </span>
          </span>
        </AmbassadorLink>
      )}

      {fishingReportTechnique && (
        <>
          <p
            className={styles.technique}
            onClick={() => {
              replaceQueryString({
                [OmniaQueryParams.technique]: fishingReportTechnique.name,
              });
              webAnalyticsClick('[product_card].(reported_on)');
              onTechniqueClick?.();
            }}
          >
            <SvgTechniques className={styles.inlineIcon} width={16} height={16} />
            <span>
              Shop{' '}
              <span>
                <em>{fishingReportTechnique.display_name_alternate}</em>
              </span>
            </span>
          </p>
        </>
      )}

      <div className={styles.bottom}>
        <p className={styles.price}>
          <LinkToProductDetailPage {...commonLinkProps}>
            <span
              className={classNames({
                [styles.price__sale]: on_sale,
              })}
            >
              ${price}
            </span>
            {on_sale && <s className={styles.priceCompare}>{toDollars(compare_at_price)}</s>}
          </LinkToProductDetailPage>
        </p>

        {buildKit && in_stock && (
          <>
            <OmniaButton
              size="lg"
              kind="tertiary"
              fontSize={14}
              onClick={() => onKitClick(singleSourceFishingReport)}
              className={styles.kitButton}
              isDisabled={skeleton}
              block
            >
              <SvgTackleBox fontSize={17} /> Build A Kit
            </OmniaButton>
          </>
        )}
        {outOfStock ? (
          <Tooltip title="Sign up to be emailed as soon as it's back in stock">
            <div>
              <OmniaButton
                size="lg"
                kind="tertiary"
                fontSize={14}
                block
                className={styles.productButtons}
                // onClick instead of onPress this is for a mobile ant design but where tooltip immediately closes onClick
                onClick={() => {
                  onOutOfStockClick();
                }}
              >
                <MailOutlined className={styles.mailIcon} />
                Email Me
              </OmniaButton>
            </div>
          </Tooltip>
        ) : (
          <OmniaButton
            size="lg"
            kind="primary"
            fontSize={14}
            isDisabled={skeleton}
            onPress={onCartAdd}
            data-test="add_to_cart"
            className={styles.productButtons}
            block
          >
            <ShoppingCartOutlined className={styles.cartIcon} /> Add to Cart
          </OmniaButton>
        )}
      </div>
    </div>
  );
};
